import mutate from 'pages/api';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { produce } from 'immer';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Input from 'components/Input';
import { useHistory } from 'react-router-dom';

interface Props {
  close: () => void;
  id: string;
  excludeLicenses: number[];
}

const RenewLicenses = ({ close, id, excludeLicenses }: Props) => {
  const history = useHistory();
  const [formData, setFormData] = useState({ publicPrivateId: id, expiry: '', name: '', email: '', excludeLicenses });

  const { mutate: renewLicenses, isLoading } = useMutation((data: typeof formData) => mutate('renewlicenses', data), {
    onSuccess: () => {
      history.push('/productorganisations');
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(
      produce((draft) => {
        draft[e.target.name] = e.target.value;
      })
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!window.confirm('This will renew licenses with the first license date and exclude selected licenses?')) return;
    renewLicenses(formData);
  };

  return (
    <Modal title="Renew Licenses" close={close}>
      <form onSubmit={handleSubmit}>
        <Input label="Expiry" name="expiry" type="date" value={formData.expiry} onChange={handleChange} />
        <div className="mt-2">
          <Button loading={isLoading}>Submit</Button>
        </div>
      </form>
    </Modal>
  );
};

export default RenewLicenses;

import React from 'react';
import Spinner from './Spinner';

type Props = {
  children: React.ReactNode;
  variant?: 'blue';
  loading?: boolean;
} & React.ButtonHTMLAttributes<HTMLElement>;

const classes = {
  blue: 'bg-color2',
};

const Button = ({ children, className, loading, variant = 'blue', ...props }: Props) => {
  return (
    <button className={`${classes[variant]} ${className}`} {...props}>
      {loading ? <Spinner speed={1} color="text-white" /> : children}
    </button>
  );
};

export default Button;

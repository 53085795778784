import type { ProductOrganisationExtra } from 'types';
import Copy from 'components/Copy';
import Spinner from 'components/Spinner';
import Container from 'layout/Container';
import React from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

const PPs = () => {
  const history = useHistory();
  const { pId, oId } = useParams<{ pId: string; oId: string }>();
  const { data } = useQuery<ProductOrganisationExtra>(`getproductorganisation/${pId}/${oId}`);

  return (
    <Container>
      {!data ? (
        <Spinner />
      ) : (
        <div>
          <div className="text-lg font-bold mr-8 mb-2">Public Keys - ({data.organisation.name + '-' + data.product.name})</div>
          <div>
            {data.publicPrivateKeys.map((e, i) => (
              <div
                key={e.id}
                className={`text-sm p-2 mb-2 break-words  border-solid rounded cursor-pointer ${
                  i === 0 ? 'border-green-400 border-2' : 'border-gray-400 border'
                }`}
                onClick={() => history.push(`/license/${e.id}`)}
              >
                <div>Created: {e.createdDate}</div>
                <div className={`mb-1 text-green-400`}>
                  <Copy value={e.publicKey} />
                  Public
                </div>
                <div className="text-xs">{e.publicKey}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Container>
  );
};

export default PPs;

import type { License } from 'types';
import Button from 'components/Button';
import Copy from 'components/Copy';
import Divider from 'components/Divider';
import Spinner from 'components/Spinner';
import Container from 'layout/Container';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import CreateLicense from './forms/CreateLicense';
import RenewLicenses from './forms/RenewLicenses';

const Licenses = () => {
  const { id } = useParams<{ id: string }>();

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [excludeLicenses, setExcludeLicenses] = useState<{ [key: string]: boolean }>({});

  const { data, refetch } = useQuery<License[]>(`getlicenses/${id}`);

  const handleClickLicense = (id: number) => {
    const temp = { ...excludeLicenses };
    if (temp[id]) {
      delete temp[id];
    } else {
      temp[id] = true;
    }
    setExcludeLicenses({ ...temp });
  };

  return (
    <Container>
      <div>
        <span className="text-lg font-bold mr-8">Licenses</span>
        <Button onClick={() => setOpen(true)}>Create</Button>
        <Button className="mx-2" onClick={() => setOpen2(true)}>
          Renew Licenses
        </Button>
        <span className="text-red-400">click on licenses to exclude from renewal</span>
      </div>
      {!data ? (
        <Spinner />
      ) : (
        <div className="mt-4 flex flex-wrap">
          {data?.map((e) => (
            <div
              onClick={() => handleClickLicense(e.id)}
              key={e.id}
              className={`text-sm p-3 rounded mb-3 w-max ml-2 border-solid border-gray-400 cursor-pointer ${
                excludeLicenses[e.id] ? 'border-red-400 border-2' : 'border'
              }`}
            >
              <div className="">Created: {e.createdDate}</div>
              <div className="w-60 break-words">Name: {e.name}</div>
              <div className="w-60 break-words">Email: {e.email}</div>
              <div className="w-60 break-words">Status: {e.status}</div>
              <Divider />
              <div className="w-60 break-words text-xs">
                <Copy value={e.licenseKey} />
                {e.licenseKey}
              </div>
            </div>
          ))}
        </div>
      )}
      {open && (
        <CreateLicense
          id={id}
          close={() => {
            refetch();
            setOpen(false);
          }}
        />
      )}
      {open2 && (
        <RenewLicenses
          id={id}
          excludeLicenses={Object.keys(excludeLicenses).map((e) => +e)}
          close={() => {
            refetch();
            setOpen2(false);
          }}
        />
      )}
    </Container>
  );
};

export default Licenses;

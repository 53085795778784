import type { ProductOrganisation } from 'types';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import Container from 'layout/Container';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import mutate from './api';
import CreatePO from './forms/CreatePO';

const POs = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const { data, refetch, isLoading } = useQuery<ProductOrganisation[]>('getproductorganisations');
  const { mutate: deletePO } = useMutation(
    ({ pId, oId }: { pId: number; oId: number }) => mutate('unlinkproductorganisation/' + pId + '/' + oId, null, 'delete'),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  return (
    <Container>
      <div>
        <span className="text-lg font-bold mr-8">Organisations' Products</span>
        <Button variant="blue" onClick={() => setOpen(true)}>
          Create
        </Button>
      </div>
      <div className="mt-2">
        {isLoading ? (
          <Spinner />
        ) : (
          data?.map((e) => (
            <div key={`${e.productId}${e.organisationId}`} className="flex items-center mb-2">
              <div
                className="cursor-pointer"
                onClick={() => history.push(`/productorganisation/${e.productId}/${e.organisationId}`)}
              >
                {e.name}
              </div>
              <i
                className="fa fa-trash ml-2 cursor-pointer text-red-600"
                onClick={() => {
                  if (!window.confirm('This will be deleted permanently! Continue?')) return;
                  deletePO({ pId: e.productId, oId: e.organisationId });
                }}
              />
            </div>
          ))
        )}
      </div>
      {open && (
        <CreatePO
          close={() => {
            refetch();
            setOpen(false);
          }}
        />
      )}
    </Container>
  );
};

export default POs;

import type { Organisation, Product } from 'types';
import mutate from 'pages/api';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { produce } from 'immer';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Select from 'components/Select';

interface Props {
  close: () => void;
}

const CreatePO = ({ close }: Props) => {
  const [formData, setFormData] = useState({ productId: '', organisationId: '' });

  const { data: organisations } = useQuery<Organisation[]>('getorganisations');
  const { data: products } = useQuery<Product[]>('getproducts');

  const { mutate: linkProductToOrganisation, isLoading } = useMutation(
    (data: typeof formData) => mutate('LinkProductToOrganisation', data),
    {
      onSuccess: () => {
        close();
      },
    }
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(
      produce((draft) => {
        draft[e.target.name] = e.target.value;
      })
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    linkProductToOrganisation(formData);
  };

  return (
    <Modal title="Create Organisation" close={close}>
      <form onSubmit={handleSubmit}>
        <Select
          label="Organisation"
          placeholder="Organisation"
          name="organisationId"
          value={formData.organisationId}
          dataName="name"
          dataValue="id"
          onChange={handleChange}
          options={organisations || []}
        />
        <Select
          label="Product"
          placeholder="Product"
          name="productId"
          value={formData.productId}
          dataName="name"
          dataValue="id"
          onChange={handleChange}
          options={products || []}
          className="mt-2"
        />
        <div className="mt-2">
          <Button loading={isLoading}>Submit</Button>
        </div>
      </form>
    </Modal>
  );
};

export default CreatePO;

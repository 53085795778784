import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { loginFail } from 'redux/actions';

interface Props {}

const Navbar = (props: Props) => {
  const dispatch = useDispatch();
  return (
    <div className="flex p-2 px-4 bg-color1 text-white items-center">
      <Link to="/" className="font-bold">
        LM
      </Link>
      <div className="ml-3 flex flex-1 justify-between items-center text-sm">
        <Link to="/generatetoken">Generate Token</Link>
        <div className="cursor-pointer" onClick={() => dispatch(loginFail())}>
          Logout
        </div>
      </div>
    </div>
  );
};

export default Navbar;

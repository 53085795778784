type RootType = {
  authenticated: boolean;
  token?: string;
};
export const initialState: any = {
  authenticated: localStorage.getItem('authenticated') === 'true' ? true : false,
  token: localStorage.getItem('token'),
};

const reducer = (state: RootType = initialState, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case 'AUTHTRUE':
      return {
        ...state,
        authenticated: true,
        token: action.payload.token,
      };
    case 'AUTHFALSE':
      return {
        ...state,
        authenticated: false,
        token: null,
      };
    default:
      return state;
  }
};

export type RootState = ReturnType<typeof reducer>;

export default reducer;
